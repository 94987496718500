import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import throttle from 'lodash/throttle';
import HanchnetLogoGradient from './images/HanchLogoGradient';
import hanchAnimeLookingOff from './images/hanch-anime-lookingoff.png';
import mountainRange from './images/mountainrange.png';
import tileConcrete from './images/tile-concrete.jpg';
import tileDiagonal from './images/tile-diagonal.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faVolumeXmark, faSoap, faGraduationCap, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faAtomSimple } from '@fortawesome/pro-regular-svg-icons';
import { faBagShopping } from '@fortawesome/pro-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';
import 'swiper/css/mousewheel';
import 'swiper/css';
import 'swiper/css/navigation';
import Marquee from './Marquee';

// NavIconsGroup Component
const NavIconsGroup = ({ iconFontSize, labelFontSize, opacity }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '20px',
    paddingInline: '20px',
    fontFamily: 'Arial Narrow',
    fontSize: labelFontSize,
    width: '100%',
  }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FontAwesomeIcon icon={faSoap} style={{ fontSize: iconFontSize, color: '#00aa88', opacity, marginBottom: `calc(${iconFontSize} / 5)` }} />
      SOAPBOX
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FontAwesomeIcon icon={faAtomSimple} style={{ fontSize: iconFontSize, color: '#ff4400', opacity, marginBottom: `calc(${iconFontSize} / 5)` }} />
      WORKS
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: iconFontSize, color: '#0077ff', opacity, marginBottom: `calc(${iconFontSize} / 5)` }} />
      LEARN
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FontAwesomeIcon icon={faBagShopping} style={{ fontSize: iconFontSize, color: '#8a2be2', opacity, marginBottom: `calc(${iconFontSize} / 5)`}} />
      SHOP
    </div>
  </div>
);

// SiteHeaderNav Component
const SiteHeaderNav = React.memo(({ scrollPosition }) => {
  const fadeStart = 180; // Start fading immediately
  const fadeEnd = 280; // End fading at 280px scroll position

  // Calculate opacity and font sizes
  const opacity = Math.min(1, Math.max(0, (scrollPosition - fadeStart) / (fadeEnd - fadeStart)));
  const inverseOpacity = 1 - opacity;
  const iconFontSize = `${Math.min(30, Math.max(0, (scrollPosition - fadeStart) / (fadeEnd - fadeStart) * 30))}px`;
  const labelFontSize = `${20 - Math.min(8, Math.max(0, (scrollPosition - fadeStart) / (fadeEnd - fadeStart) * 8))}px`;

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 660);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 660);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      backgroundColor: `rgba(0, 0, 0, ${opacity})`,
      top: 0,
      height: '80px',
      left: 0,
      right: 0,
      zIndex: 100,
    }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1200px',
        fontSize: '1.5rem',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          paddingInline: '20px',
          minWidth: '120px',
        }}>
          <div style={{
            position: 'absolute',
            fontSize: '1.25rem',
            fontFamily: 'Arial Narrow',
            fontWeight: 'bold',
            left: isMobileView ? 'auto' : 0,
            right: isMobileView ? 'auto' : 0,
            marginLeft: isMobileView ? '20px' : 0,
            opacity: inverseOpacity,
          }}>Hello</div>
          <div style={{
            opacity: opacity,
            filter: 'drop-shadow(1px 1px 0px #FF461E)',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            width: '100%',
          }}>
            <HanchnetLogoGradient />
          </div>
        </div>
        {!isMobileView && (
          <NavIconsGroup iconFontSize={iconFontSize} labelFontSize={labelFontSize} opacity={opacity} />
        )}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          minWidth: '120px',
          paddingInline: '20px',
        }}>
          <div>
            <FontAwesomeIcon icon={faVolumeXmark} />
          </div>
          <div style={{
            fontSize: '2rem',
            color: '#ffd000',
            filter: 'drop-shadow(1px 1px 0 red)',
          }}>
            <FontAwesomeIcon icon={faStar} />
          </div>
        </div>
      </div>
    </div>
  );
});

// SiteFooterNav Component
const SiteFooterNav = React.memo(() => {
  const [isVisible, setIsVisible] = useState(window.innerWidth < 660);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth < 660);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{
      position: 'fixed',
      display: isVisible ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      backgroundColor: `rgba(0, 0, 0, 1)`,
      bottom: 0,
      height: '80px',
      left: 0,
      right: 0,
      zIndex: 100,
      paddingBottom: '10px',
    }}
    >
      <NavIconsGroup iconFontSize="30px" labelFontSize="12px" opacity={1} />
    </div>
  );
});

// ScrollingTitle Component
const ScrollingTitle = React.memo(({ scrollPosition, totalHeight }) => {
  const fadeStart = 0;
  const fadeEnd = 200;

  const opacity = Math.max(0, 1 - (scrollPosition - fadeStart) / (fadeEnd - fadeStart));
  const scale = Math.max(0, 1 - (scrollPosition - fadeStart) / (fadeEnd - fadeStart));
  const scaleOffset = (1 - scale) * 15;

  const translateY = Math.round(20 * window.innerHeight / 100 - scrollPosition + scaleOffset);

  return (
    <div 
      style={{ 
        position: 'fixed',
        left: '50%',
        top: '-5%',
        transform: `translate3d(-50%, ${translateY}px, 0) scale(${scale})`,
        transition: 'transform 0.1s ease-out, opacity 0.1s ease-out',
        opacity: opacity,
        zIndex: 3,
        width: '60%',
        maxWidth: '400px',
        textAlign: 'center',
        pointerEvents: 'none',
        willChange: 'transform, opacity',
      }}
    >
      <div style={{
        filter: 'drop-shadow(0px 6px 0px #FF461E)',
      }}>
        <HanchnetLogoGradient />
      </div>
    </div>
  );
});

// StarryNightBackground Component
const StarryNightBackground = React.memo(({ starPosition }) => {
  const canvasRef = useRef(null);

  const stars = useMemo(() => Array.from({ length: 1000 }, () => ({
    x: Math.random(),
    y: Math.random(),
    radius: Math.random() * 0.8 + 0.1,
    alpha: Math.random() * 0.5 + 0.5,
  })), []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.beginPath();
      stars.forEach(star => {
        const x = star.x * canvas.width;
        const y = ((star.y - starPosition) % 1 + 1) % 1 * canvas.height;
        ctx.moveTo(x, y);
        ctx.arc(x, y, star.radius, 0, Math.PI * 2);
      });
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.fill();

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [stars, starPosition]);

  return (
    <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'black' }} />
  );
});

// UpdateLink Component
const UpdateLink = React.memo(({ scrollPosition }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(prev => !prev);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '5px',
          left: 0,
          right: 0,
          textAlign: 'center',
          pointerEvents: 'none',
          transform: `translateY(calc(37.5vh - 50% - ${scrollPosition}px))`,
          fontSize: '1.5rem',
          fontWeight: 'bold',
          fontFamily: 'Arial Narrow',
          margin: 'auto',
          width: 'fit-content',
          zIndex: '3',
          color: 'white',
        }}
      >
        <div>Latest update</div>
        <div style={{
          position: 'relative',
          color: '#ffd000',
          textShadow: '2px 2px 0 red',
        }}
        >11:50p on Mon, Jul 15
          <div 
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '-25px',
              color: 'white',
              filter: 'drop-shadow(2px 2px 0 red)',
              opacity: isVisible ? 1 : 0,
              transition: 'opacity 0.1s ease-in-out',
              cursor: 'pointer',
              pointerEvents: 'auto',
            }}
            onClick={() => console.log('Caret clicked!')}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </div>
        </div>
      </div>
    </div>
  );
});

// RegularScrollContainer Component
const RegularScrollContainer = React.memo(({ scrollPosition }) => {
  return (
    <div 
      style={{
        position: 'sticky', // Changed from 'relative'
        top: '20px', // Adjust as needed
        left: 0,
        right: 0,
        backgroundColor: 'rgba(30, 30, 30, 0.5)',
        backgroundImage: `url(${tileDiagonal})`,
        backgroundSize: '20px',
        transform: `translateY(${55 * window.innerHeight / 100 - scrollPosition}px)`, // Ensure consistent units
        transition: 'transform 0.1s ease-out',
        zIndex: 3,
        borderTop: '3px solid #555',
        borderBottom: '3px solid #555',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative', // Added for positioning children absolutely
      }}
    >
      <div 
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          textShadow: '2px 2px 0px #FF461E',
          padding: '30px',
          fontFamily: 'Arial Narrow',
        }}
      >
        UPCOMING APPEARANCES
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          position: 'relative',
          paddingInline: '20px',
          boxSizing: 'border-box',
        }}>
          <div
            style={{
              width: '100%',
              maxWidth: '1200px',
              display: 'grid',
              gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))`,
              gap: '20px',
            }}>
            {[...Array(5)].map((_, index) => (
              <div
                key={index} // Add a unique key for each child
                style={{
                  padding: '2px',
                  backgroundColor: '#000',
                  border: '7px solid #555',
                  borderRadius: '15px',
                  boxSizing: 'border-box',
                }}
              >
                <Marquee/>
              </div>
            ))}
          </div>
      </div>
      <div 
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          padding: '30px',
          fontFamily: 'Arial Narrow',
          color: '#ffd000',
          gap: '10px',
        }}
      >
        <FontAwesomeIcon icon={faSolidStar}/>
        <div style={{ textShadow: '2px 2px 0px #FF461E' }}>
          VIEW MORE
        </div>
        <FontAwesomeIcon icon={faSolidStar}/>
      </div>
      
      {/* Move KeepScrolling here */}
      <KeepScrolling />
    </div>
  );
});

// DarkGreyBox Component
const DarkGreyBox = React.memo(({ scrollPosition, totalHeight }) => {
  const titleHeight = 12 * 16; // 12rem in pixels
  const placeholderWidth = titleHeight * 0.6; // 25% of title height

  return (
    <div style={{ position: 'relative' }}>
      <div 
        style={{
          position: 'fixed',
          right: 0,
          width: '25%',
          top: `calc(50vh - ${scrollPosition * 0.3}px)`,
          height: `${totalHeight}px`,
          backgroundImage: `url(${tileConcrete})`,
          backgroundSize: '200px',
          backgroundColor: '#262B32',
          zIndex: 2,
          overflow: 'hidden',
          borderLeft: '20px solid #111114',
        }}
      >
        {[...Array(10)].map((_, index) => (
          <div
            key={index}
            style={{
              width: '100%',
              height: `${totalHeight / 10}px`,
              borderBottom: '2px solid #111',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
          <div style={{
            width: '50%',
            height: '60%',
            background: 'linear-gradient(-45deg, black, rgba(0, 0, 70, 1), black)',
            borderLeft: '10px solid black',

          }}/>
          </div>
        ))}
      </div>
      <div 
          style={{
          position: 'fixed',
          width: `${placeholderWidth}px`,
          height: `${titleHeight}px`,
          right: `calc(12.5% - ${placeholderWidth / 2}px)`,
          top: `calc(50vh - ${scrollPosition * 0.3}px - ${titleHeight}px)`,
          backgroundImage: `url(${hanchAnimeLookingOff})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          zIndex: 2
        }}
      />
    </div>
  );
});

// CityLights Component
const CityLights = React.memo(() => {
  const staticCanvasRef = useRef(null);
  const twinklingCanvasRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const animationRef = useRef();

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight * 0.55
      });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      cancelAnimationFrame(animationRef.current);
    };
  }, []);

  const getRandomColor = () => {
    const rand = Math.random();
    if (rand < 0.75) return 'rgba(255, 255, 0, 0.5)'; // yellow
    if (rand < 0.95) return 'rgba(173, 216, 230, 0.5)'; // light blue
    if (rand < 0.98) return 'rgba(255, 255, 255, 0.5)'; // white
    return 'rgba(255, 0, 0, 0.5)'; // red
  };

  const staticLights = useMemo(() => Array.from({ length: 5000 }, () => ({
    x: Math.random() * dimensions.width,
    y: Math.random() * dimensions.height,
    radius: Math.random() * 0.3 + 0.2,
    color: getRandomColor(),
  })), [dimensions]);

  const twinklingLights = useMemo(() => Array.from({ length: 1000 }, () => ({
    x: Math.random() * dimensions.width,
    y: Math.random() * dimensions.height,
    radius: Math.random() * 0.3 + 0.,
    color: getRandomColor(),
    twinkling: false,
    twinkleProgress: 0
  })), [dimensions]);

  useEffect(() => {
    if (dimensions.width === 0 || dimensions.height === 0) return;

    const staticCanvas = staticCanvasRef.current;
    const staticCtx = staticCanvas.getContext('2d');
    const twinklingCanvas = twinklingCanvasRef.current;
    const twinklingCtx = twinklingCanvas.getContext('2d');

    staticCanvas.width = twinklingCanvas.width = dimensions.width;
    staticCanvas.height = twinklingCanvas.height = dimensions.height;

    const drawStaticElements = () => {
      staticCtx.clearRect(0, 0, staticCanvas.width, staticCanvas.height);

      // Draw static lights
      staticCtx.beginPath();
      staticLights.forEach(light => {
        staticCtx.moveTo(light.x + light.radius, light.y);
        staticCtx.arc(light.x, light.y, light.radius, 0, Math.PI * 2);
      });
      staticCtx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      staticCtx.fill();

      // Draw vertical lines with dots
      const numberOfVerticalLines = 10;
      const numberOfHorizontalLines = 20;

      staticCtx.beginPath();
      for (let i = 0; i <= numberOfVerticalLines; i++) {
        const x = (i / numberOfVerticalLines) * staticCanvas.width;
        const offset = ((i - numberOfVerticalLines / 2) / numberOfVerticalLines) * staticCanvas.width * 5;

        for (let y = 0; y <= staticCanvas.height; y += 5) {
          const dotX = x + offset * (y / staticCanvas.height) + (Math.random() - 0.5) * 2;
          const dotY = y + (Math.random() - 0.5) * 2;
          staticCtx.moveTo(dotX + 1, dotY);
          staticCtx.arc(dotX, dotY, 1, 0, Math.PI);
        }
      }

      // Draw horizontal lines with dots
      for (let i = 1; i <= numberOfHorizontalLines; i++) {
        const y = (Math.pow(i / numberOfHorizontalLines, 3)) * staticCanvas.height;

        for (let x = 0; x <= staticCanvas.width; x += 10) {
          const dotX = x + (Math.random() - 0.5) * 2;
          const dotY = y + (Math.random() - 0.5) * 2;
          staticCtx.moveTo(dotX + 1, dotY);
          staticCtx.arc(dotX, dotY, 1, 0, Math.PI);
        }
      }
      staticCtx.fillStyle = 'rgba(255, 255, 0, 0.3)';
      staticCtx.fill();
    };

    // Draw static elements once
    drawStaticElements();

    const drawTwinklingElements = () => {
      twinklingCtx.clearRect(0, 0, twinklingCanvas.width, twinklingCanvas.height);

      // Draw and update twinkling lights
      twinklingCtx.beginPath();
      twinklingLights.forEach(light => {
        if (light.twinkling) {
          light.twinkleProgress += 0.1;
          if (light.twinkleProgress >= Math.PI) {
            light.twinkling = false;
            light.twinkleProgress = 0;
          }
        } else if (Math.random() < 0.001) { // Slightly increased twinkling frequency
          light.twinkling = true;
        }

        if (light.twinkling) {
          const alpha = 0.5 + Math.sin(light.twinkleProgress) * 0.5;
          twinklingCtx.moveTo(light.x + light.radius, light.y);
          twinklingCtx.arc(light.x, light.y, light.radius, 0, Math.PI * 2);
          twinklingCtx.fillStyle = light.color.replace(/[^,]+(?=\))/, alpha);
          twinklingCtx.fill();
        }
      });

      animationRef.current = requestAnimationFrame(drawTwinklingElements);
    };

    drawTwinklingElements();

    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [dimensions, staticLights, twinklingLights]);

  return (
    <>
      <canvas ref={staticCanvasRef} style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', height: '100%' }} />
      <canvas ref={twinklingCanvasRef} style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', height: '100%' }} />
    </>
  );
});

// Mountains Component
const Mountains = () => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '40%',
        width: '100%',
        backgroundImage: `url(${mountainRange})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom center',
        zIndex: 2,
      }}
    />
  );
};

// GradientAndBlackBox Component
const GradientAndBlackBox = React.memo(({ starPosition }) => {
  return (
    <div 
      style={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        height: '135vh',
        transform: `translateY(${55 - starPosition * 100}vh)`,
        transition: 'transform 0.1s ease-out',
        zIndex: 1
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          height: '25vh',
          margin: 0,
          background: 'linear-gradient(to top, rgba(0, 0, 139, 0.5), transparent)',
        }}
      >
        <Mountains />
      </div>
      <div 
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: '25vh',
          bottom: 0,
          backgroundColor: 'black',
          overflow: 'hidden',
        }}
      >
        <CityLights />
      </div>
    </div>
  );
});

// KeepScrolling Component
const KeepScrolling = ({ color = 'white', size = '2rem' }) => {
  return (
    <div style={{ 
      position: 'absolute', // Changed from 'relative' to 'absolute'
      bottom: '-200px', // Position it below the container; adjust as needed
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 10, // Ensure it's above other elements in the container
      animation: 'bounce 2s infinite', // Add bounce animation
    }}>
      <FontAwesomeIcon 
        icon={faChevronDown} 
        style={{ color: color, fontSize: size }}
      />
      
      {/* Add keyframes for bounce animation in CSS */}
      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateX(-50%) translateY(0);
            }
            40% {
              transform: translateX(-50%) translateY(-10px);
            }
            60% {
              transform: translateX(-50%) translateY(-5px);
            }
          }
        `}
      </style>
    </div>
  );
};

// ShowcaseContainer Component
const LeftSlidingContainer = React.memo(({ scrollPosition }) => {
  // Define scroll thresholds for sliding in and out
  const slideInStart = 1200; // Start sliding in
  const slideInEnd = 1700;   // Fully visible
  const slideOutStart = 4000; // Start sliding out
  const slideOutEnd = 4200;   // Fully hidden

  let transform = 'translateX(-100%)';
  if (scrollPosition >= slideInStart && scrollPosition < slideInEnd) {
    const progress = (scrollPosition - slideInStart) / (slideInEnd - slideInStart);
    transform = `translateX(${ -100 + progress * 100 }%)`; // from -100% to 0%
  } else if (scrollPosition >= slideInEnd && scrollPosition < slideOutStart) {
    transform = 'translateX(0%)';
  } else if (scrollPosition >= slideOutStart && scrollPosition < slideOutEnd) {
    const progress = (scrollPosition - slideOutStart) / (slideOutEnd - slideOutStart);
    transform = `translateX(${ 0 - progress * 100 }%)`; // from 0% to -100%
  } else if (scrollPosition >= slideOutEnd) {
    transform = 'translateX(-100%)';
  }

  return (
    <div style={{
      position: 'fixed',
      top: '20%',
      bottom: '20%',
      left: '0',
      width: '100%', // Adjust width as needed
      backgroundColor: 'rgba(20, 20, 20, 0.75)',
      transform: transform,
      transition: 'transform 0.3s ease-out',
      zIndex: 105, // Ensure it's above other elements
      padding: '20px',
      boxSizing: 'border-box',
      overflowY: 'auto',
    }}>
      <div 
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          textShadow: '2px 2px 0px #FF461E',
          padding: '30px',
          fontFamily: 'Arial Narrow',
        }}
      > SHOWCASE</div>
      {/* Add content similar to Upcoming Appearances */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}>
        
      </div>
    </div>
  );
});

// EndScreen Component
const EndScreen = React.memo(() => {
  return (
    <div style={{ height: '100vh', backgroundColor: '#1F2937', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <h2 style={{ fontSize: '6rem', fontWeight: 'bold', color: 'white' }}>Next Section</h2>
    </div>
  );
});

// Home Component
const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const totalHeight = 6000; // Total scroll height
  const fadeStartPoint = totalHeight * 0.8; // Start fading at 80% of scroll
  const fadeEndPoint = totalHeight - window.innerHeight; // End fading at the bottom of the scroll

  const handleScroll = useCallback(
    throttle(() => {
      setScrollPosition(window.pageYOffset);
    }, 16), // ~60fps
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel(); // Cancel any pending throttled calls
    };
  }, [handleScroll]);

  const starPosition = (scrollPosition / 20000) % 1;

  const backgroundOpacity = scrollPosition <= fadeStartPoint ? 1 : 
    Math.max(0, Math.min(1, 1 - (scrollPosition - fadeStartPoint) / (fadeEndPoint - fadeStartPoint)));

  return (
    <div style={{ height: `${totalHeight}px`, position: 'relative', overflow: 'hidden', backgroundColor: '#1F2937' }}>
      <div style={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: backgroundOpacity,
        pointerEvents: backgroundOpacity === 0 ? 'none' : 'auto',
        transition: 'opacity 0.3s ease-out',
      }}>
        <SiteHeaderNav scrollPosition={scrollPosition} />
        <SiteFooterNav />
        <StarryNightBackground starPosition={starPosition} />
        <GradientAndBlackBox starPosition={starPosition} />
        <DarkGreyBox scrollPosition={scrollPosition} totalHeight={totalHeight} />
        <ScrollingTitle scrollPosition={scrollPosition} />
        <UpdateLink scrollPosition={scrollPosition} />
        <RegularScrollContainer scrollPosition={scrollPosition} />
        <LeftSlidingContainer scrollPosition={scrollPosition} />
        {/* Second KeepScrolling Chevron */}
        <div style={{
          position: 'fixed',
          bottom: '50px', // Adjust as needed to position above the new container
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 106, // Above the LeftSlidingContainer
        }}>
          <KeepScrolling />
        </div>
      </div>
      <div style={{ 
        position: 'fixed', 
        top: 0,
        left: 0, 
        right: 0,
        bottom: 0, 
        opacity: 1 - backgroundOpacity,
        pointerEvents: backgroundOpacity === 0 ? 'auto' : 'none',
        transition: 'opacity 0.3s ease-out',
        zIndex: 4
      }}>
        <EndScreen />
      </div>
      <div className="side-gradient left"></div>
      <div className="side-gradient right"></div>
    </div>
  );
};

export default Home;
